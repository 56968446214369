import { type ReactNode } from 'react';
import cx from 'clsx';
import { useIntl } from '@qonto/react-migration-toolkit/react/hooks';
import styles from './card-review-panel.strict-module.css';

interface CardReviewPanelProps {
  className?: string;
  color?: string;
  description: string;
  iconSrc: string;
  name: string;
}

export function CardReviewPanel({
  className: classNameProp,
  color = '', // default value needed due to typechecking in "t"
  description,
  iconSrc,
  name,
}: CardReviewPanelProps): ReactNode {
  const { t } = useIntl();

  return (
    <div className={cx(styles.panel, classNameProp)} data-testid="card-review-panel">
      <img alt="" className={styles.icon} src={iconSrc} />
      <p className={cx('body-1', styles.description)} data-testid="card-review-panel-description">
        <span>{name} </span>
        <span>{description} </span>
        {Boolean(color) && (
          <span className="sr-only">
            {t('cards.steps.review.card-color', { cardColor: color })}
          </span>
        )}
      </p>
    </div>
  );
}
