import React, { type ReactNode } from 'react';
import { ListBoxItem } from 'react-aria-components';
import cx from 'clsx';
import type { CashflowCategory } from 'qonto/react/models/cash-flow-category';
import styles from './styles.strict-module.css';
import { CategoryIcon } from './category-icon';

interface CategoryListBoxItemProps {
  item: CashflowCategory;
  showIcon?: boolean;
}

export function CategoryListBoxItem({
  item,
  showIcon = true,
}: CategoryListBoxItemProps): ReactNode {
  const { id, name } = item;
  const categoryName = name ?? '';

  return (
    <ListBoxItem
      id={id}
      textValue={categoryName}
      className={cx(styles['category-list-item'])}
      data-testid="category-list-item"
    >
      <Category item={item} showIcon={showIcon} />
    </ListBoxItem>
  );
}

interface CategoryProps extends CategoryListBoxItemProps {
  emphasized?: boolean;
}

export function Category({ item, showIcon = true, emphasized = false }: CategoryProps): ReactNode {
  const { name, icon, colorKey } = item;
  const categoryName = name ?? '';

  return (
    <div className={cx(styles.category, emphasized ? 'body-1' : 'body-2')}>
      <CategoryIcon icon={showIcon ? icon : null} color={colorKey} />
      <span data-testid="category-name">{categoryName}</span>
    </div>
  );
}
