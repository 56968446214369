import React, { useEffect, useState } from 'react';
import { Popup } from '@repo/design-system-kit';
import { DialogTrigger } from 'react-aria-components';
import {
  useEmberService,
  useFlags,
  useIntl,
  useRouter,
} from '@qonto/react-migration-toolkit/react/hooks';
import window from 'ember-window-mock';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import ENV from 'qonto/config/environment';
import { Illustration } from './illustration';
import styles from './styles.strict-module.css';

export const NRC_POPUP_STORAGE_KEY = 'nrcpromopopupdismissed';

interface NrcPromotionalPopupProps {
  hasSpainTaxesAbility: boolean;
  popupDelay?: number;
}

export function NrcPromotionalPopup({
  hasSpainTaxesAbility,
  popupDelay = ENV.environment === 'test' ? 0 : 2000,
}: NrcPromotionalPopupProps): React.ReactNode {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const { featureBooleanNrcPaymentsPromoSignedUpUsers } = useFlags();
  const segment = useEmberService('segment');
  const { organization } = useOrganizationManager();
  const { t } = useIntl();
  const legalCountry = organization.legalCountry as string;
  const underRegistration = organization.underRegistration as boolean;

  useEffect(() => {
    if (
      underRegistration ||
      !featureBooleanNrcPaymentsPromoSignedUpUsers ||
      !hasSpainTaxesAbility
    ) {
      return;
    }

    const timeout = setTimeout(() => {
      if (window.localStorage.getItem(NRC_POPUP_STORAGE_KEY) !== '1') {
        setIsOpen(true);
        segment.track('agencia-tributaria_modal-pop-up_seen');
        window.localStorage.setItem(NRC_POPUP_STORAGE_KEY, '1');
      }
    }, popupDelay);
    return () => {
      clearTimeout(timeout);
    };
  }, [
    legalCountry,
    underRegistration,
    featureBooleanNrcPaymentsPromoSignedUpUsers,
    hasSpainTaxesAbility,
    popupDelay,
    segment,
  ]);

  const handleConfirm = (): void => {
    segment.track('agencia-tributaria_modal-pop-up_taxes-clicked');
    void router.push(`/organizations/${organization.slug}/agencia-tributaria`);
  };

  const handleCancel = (): void => {
    segment.track('agencia-tributaria_modal-pop-up_taxes-closed');
    setIsOpen(false);
  };

  // react-aria modal escapes the test container when running tests,
  // so we need to render it where it can find the modal (i.e. <main> component)
  const isTest = ENV.environment === 'test';
  const TEST_PORTAL_CONTAINER = document.querySelector('main') ?? undefined;

  return (
    <DialogTrigger>
      <Popup
        title={t('aeat.pop-up.title')}
        subtitle={t('aeat.pop-up.subtitle')}
        type="promotional"
        illustration={<Illustration />}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        confirmText={t('aeat.pop-up.cta')}
        cancelText={t('btn.close')}
        isOpen={isOpen}
        className={styles.popup}
        UNSTABLE_portalContainer={isTest ? TEST_PORTAL_CONTAINER : undefined}
      >
        <p data-test-id="nrc-promotional-popup-list-header">{t('aeat.pop-up.list-header')}</p>
        <ul>
          <li>{t('aeat.pop-up.list-point-1')}</li>
          <li>{t('aeat.pop-up.list-point-2')}</li>
        </ul>
      </Popup>
    </DialogTrigger>
  );
}
