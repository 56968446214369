import React, { type ReactNode } from 'react';
import cx from 'clsx';
import styles from './styles.strict-module.css';

interface CategoryIconProps {
  color: string;
  icon: string | null;
}

export function CategoryIcon({ color, icon }: CategoryIconProps): ReactNode {
  const withIcon = icon ? styles['with-icon'] : '';

  return (
    <div className={cx(styles['category-icon-container'])}>
      <div
        style={{
          backgroundColor: `var(${color})`,
        }}
        className={cx(styles['category-color-dot'], withIcon)}
        data-testid="category-color-dot"
      >
        {icon ? <span data-testid="category-icon">{icon}</span> : null}
      </div>
    </div>
  );
}
