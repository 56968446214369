import React, { useState, type Key, type ReactNode } from 'react';
import {
  Button,
  Label,
  ListBox,
  Popover,
  Select,
  Collection,
  Section,
  Header,
} from 'react-aria-components';
import cx from 'clsx';
import type { CashflowCategories } from 'qonto/react/models/cash-flow-category';
import { ChevronBottomOutlined } from 'qonto/react/assets/icons';
import { getFlatCategories } from '../utils/cash-flow-categories';
import styles from './styles.strict-module.css';
import { Category } from './category';
import { ParentCategory } from './parent-category';

interface CashflowCategorySelectorProps {
  categories: CashflowCategories;
  activeCategoryId: string | undefined;
  onSelectionChange: (categoryId: string) => void;
}
export function CashflowCategorySelector({
  categories = {
    inflow: [],
    outflow: [],
  },
  activeCategoryId,
  onSelectionChange,
}: CashflowCategorySelectorProps): ReactNode {
  const { inflow, outflow } = categories;

  const flatCategories = getFlatCategories(categories);
  const hasCategories = flatCategories.length > 0;
  const categoriesData = [
    { id: 'inflow', name: 'Inflows', categories: inflow },
    { id: 'outflow', name: 'Outflows', categories: outflow },
  ];

  const [selectedCategoryId, setSelectedCategoryId] = useState(activeCategoryId);
  const handleSelectionChange = (key: Key): void => {
    setSelectedCategoryId(key as string);
    onSelectionChange(key as string);
  };

  const displaySelectedCategory = (): ReactNode => {
    const selectedCategory = flatCategories.find(category => category.id === selectedCategoryId);
    if (selectedCategory) return <Category item={selectedCategory} emphasized />;

    return (
      <span className={cx(styles['select-placeholder'])}>
        {hasCategories ? 'Select a category' : 'No categories available'}
      </span>
    );
  };

  return (
    <Select
      className={cx(styles.select)}
      selectedKey={selectedCategoryId}
      onSelectionChange={handleSelectionChange}
      data-testid="cash-flow-category-selector"
      isDisabled={!hasCategories}
    >
      <Label className={cx(styles.title)} data-testid="title">
        Category
      </Label>
      <Button className={cx('body-1', 'overlay', styles.trigger)} data-testid="trigger">
        {displaySelectedCategory()}
        <ChevronBottomOutlined aria-hidden="true" className={cx(styles['arrow-icon'])} />
      </Button>
      {hasCategories ? (
        <Popover data-testid="cash-flow-category-popover" className={cx(styles.popover)}>
          <ListBox items={categoriesData} className={cx(styles['list-box'])}>
            {inOutFlow => {
              if (!inOutFlow.categories.length) return null;
              return (
                <Section id={inOutFlow.name} className={cx(styles.section)} data-testid="section">
                  <Header className={cx(styles['section-title'])} data-testid="section-title">
                    {inOutFlow.name}
                  </Header>
                  <Collection items={inOutFlow.categories}>
                    {item => {
                      return <ParentCategory category={item} />;
                    }}
                  </Collection>
                </Section>
              );
            }}
          </ListBox>
        </Popover>
      ) : null}
    </Select>
  );
}
